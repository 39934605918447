import axios from "@/api/config/interceptor";
import { host } from "@/api/config/host";
import { _paramsToQueryString } from "@/utils/utils";

// 查询船东信息
export const shipOwnerInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v3/Shipowner/DetailByTC`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 查询船舶信息
export const shipInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v3/Ship/DetailByTC`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
