<template>
  <div class="pdfWrap" v-loading="flag.loadVehicleInfo">
    <div style="margin-bottom:10px;">
      <el-checkbox v-model="isMark">标记该船东为“已临时税务登记”</el-checkbox>
      <!-- <span style="font-size: 14px;"></span> -->
    </div>

    <div>
      <!-- <el-select v-model="currentCard" placeholder="请选择证件" style="margin-right: 8px;">
        <el-option label="全部" :value="6"></el-option>
        <el-option label="1证" :value="1"></el-option>
        <el-option label="2证" :value="2"></el-option>
        <el-option label="3证" :value="3"></el-option>
        <el-option label="4证" :value="4"></el-option>
        <el-option label="5证" :value="5"></el-option>
      </el-select> -->
      <el-select v-model="currentShipName" placeholder="请选择船舶" style="margin-right: 8px;">
        <el-option v-for="item in shipList" :key="item.ShipName" :label="item.ShipName"
          :value="item.ShipName">
        </el-option>
      </el-select>
      <el-button v-print="printObj" type="primary" size="small" icon="el-icon-printer">打印</el-button>
      <el-button type="primary" size="small" :icon="downloadIcon" @click="downloadPDF()">下载</el-button>
    </div>
    <div id="printMe" v-if="shipOwnerInfo">
      <el-divider content-position="center">
        <el-tag type="info">基本信息</el-tag>
      </el-divider>
      <el-table :data="shipOwnerInfoList" :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
        <el-table-column align="center" prop="ShipownerName" label="人员姓名"></el-table-column>
        <el-table-column align="center" prop="ShipownerIDCard" label="人员身份证号"></el-table-column>
        <el-table-column align="center" prop="TelPhone" label="人员手机号码"></el-table-column>
        <el-table-column align="center" prop="ShipName" label="船舶名称"></el-table-column>
      </el-table>
      <el-divider content-position="center">
        <el-tag type="danger">证件信息</el-tag>
      </el-divider>
      <div style="column-count: 2;margin:0 20rpx;">
        <div class="upBox">
          <div class="img-box">
            <el-image class="img" :src="shipOwnerInfo.IDCardFrontUrl"
              :preview-src-list="[shipOwnerInfo.IDCardFrontUrl]" fit="cover"></el-image>
          </div>
          <div class="upText">身份证(正面)</div>
        </div>
        <div class="upBox">
          <div class="img-box">
            <el-image class="img" :src="shipOwnerInfo.IDCardBackUrl"
              :preview-src-list="[shipOwnerInfo.IDCardBackUrl]" fit="cover"></el-image>
          </div>
          <div class="upText">身份证(反面)</div>
        </div>
        <div class="upBox"  v-if="shipOwnerInfo.SeamanPassportUrl">
          <div class="img-box">
            <el-image class="img" :src="shipOwnerInfo.SeamanPassportUrl"
              :preview-src-list="[shipOwnerInfo.SeamanPassportUrl]" fit="cover"></el-image>
          </div>
          <div class="upText">船员证</div>
        </div>
        <div class="upBox" v-if="shipOwnerInfo.FlightManagementBookUrl">
          <div class="img-box">
            <el-image class="img" :src="shipOwnerInfo.FlightManagementBookUrl"
              :preview-src-list="[shipOwnerInfo.FlightManagementBookUrl]" fit="cover"></el-image>
          </div>
          <div class="upText">船员航务薄</div>
        </div>
        <div class="upBox" v-if="shipConfirmInfo.RiverLicenseURL">
          <div class="img-box">
            <el-image class="img" :src="shipConfirmInfo.RiverLicenseURL"
              :preview-src-list="[shipConfirmInfo.RiverLicenseURL]" fit="cover"></el-image>
          </div>
          <div class="upText">船舶内河适航证</div>
        </div>
        <div class="upBox" v-if="shipConfirmInfo.ShipLicenseFrontURL">
          <div class="img-box">
            <el-image class="img" :src="shipConfirmInfo.ShipLicenseFrontURL"
              :preview-src-list="[shipConfirmInfo.ShipLicenseFrontURL]" fit="cover"></el-image>
          </div>
          <div class="upText">船舶营运证正面</div>
        </div>
        <div class="upBox" v-if="shipConfirmInfo.ShipLicenseBackURL">
          <div class="img-box">
            <el-image class="img" :src="shipConfirmInfo.ShipLicenseBackURL"
              :preview-src-list="[shipConfirmInfo.ShipLicenseBackURL]" fit="cover"></el-image>
          </div>
          <div class="upText">船舶营运证反面</div>
        </div>
        <div class="upBox" v-if="shipConfirmInfo.ShipPhotoURL">
          <div class="img-box">
            <el-image class="img" :src="shipConfirmInfo.ShipPhotoURL"
              :preview-src-list="[shipConfirmInfo.ShipPhotoURL]" fit="cover"></el-image>
          </div>
          <div class="upText">船舶图片</div>
        </div>
        <div class="upBox" v-if="shipConfirmInfo.AISURL">
          <div class="img-box">
            <el-image class="img" :src="shipConfirmInfo.AISURL"
              :preview-src-list="[shipConfirmInfo.AISURL]" fit="cover"></el-image>
          </div>
          <div class="upText">AIS证书</div>
        </div>
        <div class="upBox" v-if="shipConfirmInfo.RadioLicenseURL">
          <div class="img-box">
            <el-image class="img" :src="shipConfirmInfo.RadioLicenseURL"
              :preview-src-list="[shipConfirmInfo.RadioLicenseURL]" fit="cover"></el-image>
          </div>
          <div class="upText">电台执照</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script> 
import {
  shipInfo,
} from "@/api/evidenceInfo/shipEvidenceInfo";
import {
  UpdateTaxRegisterShip
} from "@/api/transport/ship/shipOwner";
import { _getBase64 } from "@/utils/utils"
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
export default {
  props: {

    //船东信息
    _shipOwnerInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    //船舶列表
    _shipList: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      isMark: true,
      flag: {
        loadVehicleInfo: false, //获取车辆信息
      },
      downloadIcon: 'el-icon-bottom',
      printObj: {
        id: 'printMe',
        popTitle: '',
        // modalMessage: '',
        clickMounted: () => {
          this.UpdateTaxRegister()
        }
      },
      shipList: [], //船舶列表
      shipOwnerInfoList:[],
      shipOwnerInfo: {}, //船东信息
      shipConfirmInfo: {}, //船舶信息
      currentShipName: '', //当前选择的船舶名称
      // currentCard: 6,//选择的证件展示
    }
  },
  methods: {
    UpdateTaxRegister() {
      if (this.isMark) {
        let data = {
          User_IDS: [],
          TaxRegisterStatus: '2', // 税务临时登记
          TaxRegisterRemark: '',
        };
        var userids={
          UserID:this.shipOwnerInfo.ShipownerUserID,
          AscriptionUserID:this.shipOwnerInfo.AscriptionUserID,
        }
        data.User_IDS.push(userids);
        UpdateTaxRegisterShip(data).then((res) => {
          this.$emit("isClickPrint",true)
        });
      }
    },
    // 下载
    downloadPDF() {
      this.downloadIcon = 'el-icon-loading'
      html2canvas(document.getElementById("printMe")).then((canvas) => {
        var contentWidth = canvas.width
        var contentHeight = canvas.height
        //一页pdf显示html页面生成的canvas高度
        var pageHeight = contentWidth / 592.28 * 841.89
        //未生成pdf的html页面高度
        var leftHeight = contentHeight
        //页面偏移
        var position = 0
        //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        var imgWidth = 595.28
        var imgHeight = 592.28 / contentWidth * contentHeight
        var pageData = canvas.toDataURL('image/jpeg', 1.0)
        var pdf = new jsPDF('', 'pt', 'a4')
        //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        //当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
          pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            //避免添加空白页
            if (leftHeight > 0) {
              pdf.addPage()
            }
          }
        }
        //设置文件名
        pdf.save(`${this.shipOwnerInfo.ShipownerName}-${this.shipOwnerInfo.TelPhone}.pdf`)
      }).finally(() => {
        this.downloadIcon = 'el-icon-bottom'
      })
    },

   
  },
  created() {
    this.shipList = this._shipList;
    this.shipOwnerInfo=this._shipOwnerInfo;
    if(this.shipList.length>0){
      this.currentShipName = this.shipList[0].ShipName;
      this.shipOwnerInfo.ShipName=this.currentShipName;
    }else{
      this.shipOwnerInfo.ShipName="无";
    }
    
    this.printObj.popTitle = `姓名：${ this.shipOwnerInfo.ShipownerName}  身份证号：${this.shipOwnerInfo.ShipownerIDCard}} 手机号码：${this.shipOwnerInfo.TelPhone} 船舶名称：${this.currentShipName}`
    
    this.shipOwnerInfoList=[];
    this.shipOwnerInfoList.push(this.shipOwnerInfo);
  },
  watch: {
    // _shipList(val) {
    //   this.shipList = val
    // },

    //下拉框变化获取车辆信息
    currentShipName(val) {
      //this.flag.loadVehicleInfo = true
      shipInfo({ ShipName: val })
      .then((res) => {
        if (res.data.ShipConfirmInfo == null) return;
        this.shipConfirmInfo=res.data.ShipConfirmInfo
       
        if(this.shipConfirmInfo.RiverLicenseURL){
            _getBase64(this.shipConfirmInfo.RiverLicenseURL).then(RiverLicenseURL => {
            this.shipConfirmInfo.RiverLicenseURL = RiverLicenseURL
          })
        }
        if(this.shipConfirmInfo.ShipLicenseFrontURL){
            _getBase64(this.shipConfirmInfo.ShipLicenseFrontURL).then(ShipLicenseFrontURL => {
            this.shipConfirmInfo.ShipLicenseFrontURL = ShipLicenseFrontURL
          })
        }
        if(this.shipConfirmInfo.ShipLicenseBackURL){
            _getBase64(this.shipConfirmInfo.ShipLicenseBackURL).then(ShipLicenseBackURL => {
            this.shipConfirmInfo.ShipLicenseBackURL = ShipLicenseBackURL
          })
        }
        if(this.shipConfirmInfo.ShipPhotoURL){
            _getBase64(this.shipConfirmInfo.ShipPhotoURL).then(ShipPhotoURL => {
            this.shipConfirmInfo.ShipPhotoURL = ShipPhotoURL
          })
        }
        if(this.shipConfirmInfo.AISURL){
            _getBase64(this.shipConfirmInfo.AISURL).then(AISURL => {
            this.shipConfirmInfo.AISURL = AISURL
          })
        }
        if(this.shipConfirmInfo.RadioLicenseURL){
            _getBase64(this.shipConfirmInfo.RadioLicenseURL).then(RadioLicenseURL => {
            this.shipConfirmInfo.RadioLicenseURL = RadioLicenseURL
          })
        }
        this.printObj.popTitle = `姓名：${ this.shipOwnerInfo.ShipownerName}  身份证号：${this.shipOwnerInfo.ShipownerIDCard}} 手机号码：${this.shipOwnerInfo.TelPhone} 船舶名称：${this.currentShipName}`
        this.shipOwnerInfo.ShipName=this.currentShipName;
        this.shipOwnerInfoList=[];
        this.shipOwnerInfoList.push({...this.shipOwnerInfo});
      }).finally(() => {
        this.flag.loadVehicleInfo = false
      })
    }
  },
}
</script>


<style lang="scss" scoped>
.upBox {
  margin-bottom: 15px;
  break-inside: avoid;
}

.img-box {
  width: 100%;

  .img {
    width: 100%;
  }
}

.upText {
  text-align: center;
}
</style>